export const environment = {
  production: true,
  serverBaseUrl: 'yosgsw4:3001',
  baseUrl: 'https://api.phantomlib.com',
  cdnBaseUrl: 'https://cdn.phantomlib.com',
  tokenInterceptor: {
    pathShouldStartWith: ['/api'],
    blacklistedPaths: ['/auth/*'],
  },
  github: {
    authUrl: 'https://github.com/login/oauth/authorize',
    clientId: '69b241ea19f02504b577',
    redirectPath: '/app/auth/github',
    scopeArr: ['read:user', 'user:email'],
    allowSignup: 'true',
  },
  google: {
    authUrl: 'https://accounts.google.com/o/oauth2/v2/auth',
    clientId:
      '1039619108410-prfkgcclj3au4pnhpjtj0lr9mvca8gnn.apps.googleusercontent.com',
    redirectPath: '/app/auth/google',
    scopeArr: [
      'https://www.googleapis.com/auth/userinfo.email',
      'https://www.googleapis.com/auth/userinfo.profile',
    ],
    responseType: 'code',
    accessType: 'offline',
    prompt: 'consent',
  },
  facebook: {
    authUrl: 'https://www.facebook.com/v16.0/dialog/oauth',
    clientId: '995494401622609',
    redirectPath: '/app/auth/facebook',
    scopeArr: ['email', 'user_friends'],
    responseType: 'code',
    authType: 'rerequest',
    display: 'popup',
  },
};
