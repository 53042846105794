import { Routes } from '@angular/router';
import { resolveHome } from './views/home/home.component';
import { resolveSinglePost } from './views/single-post/single-post.component';
import { resolveSubpage } from './views/subpage/subpage.component';

export const routes: Routes = [
  {
    path: 'app/auth',
    loadChildren: () => import('./views/auth/auth.routes'),
  },
  {
    path: '',
    loadComponent: () => import('./components/layout/layout.component'),
    children: [
      {
        path: '',
        loadComponent: () => import('./views/home/home.component'),
        resolve: {
          ...resolveHome,
        },
      },
      {
        path: 'category',
        children: [
          {
            path: ':category',
            loadComponent: () => import('./views/home/home.component'),
            resolve: {
              ...resolveHome,
            },
          },
          {
            path: ':category/:type',
            loadComponent: () => import('./views/subpage/subpage.component'),
            resolve: {
              ...resolveSubpage,
            },
          },
          {
            path: ':category/:type/:postId',
            loadComponent: () =>
              import('./views/single-post/single-post.component'),
            resolve: {
              ...resolveSinglePost,
            },
          },
        ],
      },
      {
        path: 'type',
        children: [
          {
            path: ':type',
            loadComponent: () => import('./views/subpage/subpage.component'),
            resolve: {
              ...resolveSubpage,
            },
          },
          {
            path: ':type/:category',
            loadComponent: () => import('./views/subpage/subpage.component'),
            resolve: {
              ...resolveSubpage,
            },
          },
        ],
      },
      {
        path: 'tag',
        children: [
          {
            path: ':tag',
            loadComponent: () => import('./views/subpage/subpage.component'),
            resolve: {
              ...resolveSubpage,
            },
          },
        ],
      },
      {
        path: 'legal',
        loadChildren: () => import('./views/legal/legal.routes'),
      },
      {
        path: 'profile',
        loadChildren: () => import('./views/profile/profile.routes'),
      },
      {
        path: 'admin-panel',
        loadChildren: () => import('./views/admin-panel/admin-panel.routes'),
      },
    ],
  },
  {
    path: '404',
    loadComponent: () => import('./views/not-found/not-found.component'),
  },
  {
    path: '**',
    redirectTo: '404',
    pathMatch: 'full',
  },
];
