import {
  ApplicationConfig,
  PLATFORM_ID,
  provideExperimentalZonelessChangeDetection,
} from '@angular/core';
import {
  provideRouter,
  withComponentInputBinding,
  withViewTransitions,
} from '@angular/router';

import {
  IMAGE_CONFIG,
  IMAGE_LOADER,
  ImageLoaderConfig,
  isPlatformServer,
} from '@angular/common';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withFetch,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MAT_ICON_DEFAULT_OPTIONS } from '@angular/material/icon';
import { provideClientHydration } from '@angular/platform-browser';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { environment } from 'src/environments/environment';
import { ENVIRONMENT_TOKEN } from 'src/environments/environment-token';
import { routes } from './app.routes';
import { TokenInterceptor } from './interceptors/token.interceptor';
import { LOCALSTORAGE } from './utils/localstorage.token';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes, withComponentInputBinding(), withViewTransitions()),
    provideHttpClient(withInterceptorsFromDi(), withFetch()),
    provideClientHydration(),
    provideAnimationsAsync(),
    provideExperimentalZonelessChangeDetection(),
    LOCALSTORAGE,

    {
      provide: ENVIRONMENT_TOKEN,
      useFactory: () => {
        const platformId = PLATFORM_ID;

        if (isPlatformServer(platformId)) {
          return {
            ...environment,
            baseUrl: environment.serverBaseUrl,
          };
        }

        return environment;
      },
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: IMAGE_LOADER,
      // return `https://example.com/images?src=${config.src}&width=${config.width}`;
      useValue: (config: ImageLoaderConfig) =>
        `${environment.cdnBaseUrl}/phantom/${
          config.width ? config.width : 'original'
        }/${config.src}`,
    },
    {
      provide: IMAGE_CONFIG,
      useValue: {
        breakpoints: [48, 128, 640, 828, 1200],
      },
    },
    {
      provide: MAT_ICON_DEFAULT_OPTIONS,
      useValue: {
        fontSet: 'phb',
      },
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'outline',
      },
    },
  ],
};
