import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { ENVIRONMENT_TOKEN } from 'src/environments/environment-token';
import { AuthState } from '../views/auth/auth.state';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private env = inject(ENVIRONMENT_TOKEN);
  private authState = inject(AuthState);

  pathShouldStartWith = this.env.tokenInterceptor.pathShouldStartWith;
  blacklistedPaths = this.env.tokenInterceptor.blacklistedPaths;

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const token = this.authState.$token() || '';
    const urlObj = new URL(req.url);
    const pathShouldStartWith = this.pathShouldStartWith.find(startWith =>
      urlObj.pathname.startsWith(startWith)
    );

    const pathIsBlacklisted = this.blacklistedPaths.some(path => {
      if (path.includes('/*')) {
        const pathWithoutWildcard = path.replace('/*', '');
        return urlObj.pathname.startsWith(pathWithoutWildcard);
      }

      return urlObj.pathname === path;
    });

    if (token === null || !pathShouldStartWith || pathIsBlacklisted) {
      return next.handle(req);
    }

    req = req.clone({
      headers: req.headers.append('Authorization', `Bearer ${token}`),
    });

    return next.handle(req).pipe(
      tap(httpEvent => {
        if (httpEvent instanceof HttpResponse) {
          if (httpEvent.headers.has('refreshedToken')) {
            // TODO create a refresh token timer (when logged in for more than 1 hour logout if not refreshed)
            // TODO on token refresh, refresh token timer
            const refreshedToken = httpEvent.headers.get('refreshedToken');

            refreshedToken && this.authState.setToken(refreshedToken);
          }
        }
      })
    );
  }
}
